import { useRouter } from "next/router";
import { Menu } from "antd";
import {
  LogoutOutlined,
  SettingOutlined,
  FileOutlined,
  HeartOutlined,
  TrophyOutlined
} from "@ant-design/icons";
import useWebInstance from "grandus-lib/hooks/useWebInstance";
import {useTranslation} from "next-i18next";

const UserMenu = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { settings } = useWebInstance();
  return (
    <Menu
      onClick={({ item, key, keyPath, domEvent }) => {
        router.push(
          {
            pathname: key,
            query: {},
          },
          {
            pathname: key,
            query: {},
          }
        );
      }}
      defaultSelectedKeys={[router.pathname]}
      mode="inline"
    >
      <Menu.Item key="/ucet/profil" icon={<SettingOutlined />}>
        {t("Môj účet")}
      </Menu.Item>
      <Menu.Item key="/ucet/objednavky" icon={<FileOutlined />}>
        {t("Objednávky")}
      </Menu.Item>
      <Menu.Item key="/wishlist" icon={<HeartOutlined />}>
        {t("Wishlist")}
      </Menu.Item>
      <Menu.Item key="/ucet/vernostne-body" icon={<TrophyOutlined />}>
        {t("Vernostné body")}
      </Menu.Item>
      <Menu.ItemGroup>
        <Menu.Item danger={true} key="/odhlasenie" icon={<LogoutOutlined />}>
          {("Odhlásenie")}
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
};

export default UserMenu;
